.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: #f1f1f1;
    color: #000000;
  }

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused .dx-icon {
    color: #000000;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: rgba(245, 244, 244, 0.7);
    color: #fff;
}


.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: #f3f2f3;
    color: #000;
  }

.sectionTitle{
  width: 100%;
  background-color: rgb(66, 133, 244, 0.09);
  padding: 5px;
}
 
