body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFormHelperText-root {color: red !important;}

.webviewer {
  flex: 1 1;
  margin: 8px;
  box-shadow: 1px 1px 10px #999;
}
.styles_dx-list__3W6Wy:not(.styles_dx-list-select-decorator-enabled__1M8zo) .styles_dx-list-item__3fQXh.styles_dx-state-focused__13J2k {
    background-color: #f1f1f1;
    color: #000000;
  }

.styles_dx-list__3W6Wy:not(.styles_dx-list-select-decorator-enabled__1M8zo) .styles_dx-list-item__3fQXh.styles_dx-state-focused__13J2k .styles_dx-icon__Db-84 {
    color: #000000;
}

.styles_dx-list__3W6Wy:not(.styles_dx-list-select-decorator-enabled__1M8zo) .styles_dx-list-item__3fQXh.styles_dx-state-focused__13J2k.styles_dx-list-item-selected__3K4ir {
    background-color: rgba(245, 244, 244, 0.7);
    color: #fff;
}


.styles_dx-list__3W6Wy:not(.styles_dx-list-select-decorator-enabled__1M8zo) .styles_dx-list-item__3fQXh.styles_dx-state-active__5EvW4 {
    background-color: #f3f2f3;
    color: #000;
  }

.styles_sectionTitle__BW6xj{
  width: 100%;
  background-color: rgb(66, 133, 244, 0.09);
  padding: 5px;
}
 

.sigStyles_container__3djgz {
    width: 100%;
    height: 100vh;
    top: 10%;
    left: 10%;
  }
  
.sigStyles_sigContainer__1VN_j {
    margin: 0 auto;    
    position: relative;
    border: 1px solid #ccc !important;
    
  }
  
.sigStyles_sigPad__2Tlhq {
  
    background-color: white;
    background-image: url("/assets/images/sign-here.png");
    
  }
  
.sigStyles_buttons__2C3lL {
    width: 100%;
    height: 30px;
  }
  
.sigStyles_sigImage__2rhNP {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-color: white;
  } 
