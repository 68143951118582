.container {
    width: 100%;
    height: 100vh;
    top: 10%;
    left: 10%;
  }
  
.sigContainer {
    margin: 0 auto;    
    position: relative;
    border: 1px solid #ccc !important;
    
  }
  
.sigPad {
  
    background-color: white;
    background-image: url("/assets/images/sign-here.png");
    
  }
  
.buttons {
    width: 100%;
    height: 30px;
  }
  
.sigImage {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-color: white;
  } 